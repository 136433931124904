<section
    class="bg-surface-A border-surface-chat-A flex flex-col rounded-2xl border-4 p-6"
>
    <button
        class="bg-surface-B flex items-center rounded-lg"
        type="button"
        (click)="attachmentClicked.emit()"
    >
        <interacta-attachment-preview [attachment]="attachment">
        </interacta-attachment-preview>
        <section class="flex grow flex-col items-start overflow-hidden p-8">
            <interacta-inner-ellipses-text
                class="typo-sm-bold text-text max-w-full"
                [matTooltip]="attachment.name"
                [text]="attachment.name"
            ></interacta-inner-ellipses-text>
            <p class="typo-sm text-text overflow-ellipsis text-nowrap">
                {{ attachment.creatorUser.fullName }}
            </p>
            <interacta-creation-date
                class="typo-sm text-text-mid-contrast"
                [date]="attachment.creationTimestamp"
            >
            </interacta-creation-date>
        </section>
    </button>
    @if (showThreadButton) {
        <button
            class="mt-8 flex items-center gap-x-4"
            type="button"
            (click)="goToThread.emit()"
        >
            <span class="typo-sm text-text"
                >{{
                    (messagesCount === 1
                        ? 'AI.MESSAGES_IN_THREAD_SING'
                        : 'AI.MESSAGES_IN_THREAD_PLUR'
                    ) | translate: { count: messagesCount }
                }}
            </span>
            <interacta-icon
                class="size-24"
                [icon]="'chevron-small-right'"
            ></interacta-icon>
            @if (hasErrors) {
                <interacta-icon
                    class="text-text-error ml-auto size-24"
                    [attr.aria-label]="'AI.ERROR_THREAD' | translate"
                    [icon]="'warning'"
                    [matTooltip]="'AI.ERROR_THREAD' | translate"
                ></interacta-icon>
            }
        </button>
    }
</section>
