<ng-container
    *ngIf="{
        uploadState: state$ | async,
        attachments: attachments$ | async,
        notCanceledAttachmentsCount: notCanceledAttachmentsCount$ | async,
        completedUploads: completedUploads$ | async,
        onLoading: attachmentInputService.onLoading$ | async
    } as $"
>
    <div
        *ngIf="$.uploadState && $.uploadState.isDialogOpen && $.attachments"
        class="w-464 py-18 bg-surface-A border-border-300 shadow-upload absolute bottom-0 right-0 flex flex-col space-y-16 rounded-xl border border-solid px-16"
        [ngClass]="{
            'h-56': $.uploadState.isDialogCompact
        }"
    >
        <!-- header -->
        <div class="flex items-center justify-between">
            <span class="typo-sm-bold">
                {{ $.completedUploads }} /
                {{ $.notCanceledAttachmentsCount }}
                {{ 'POST.LABEL_ATTACHMENTS_COUNTER' | translate }}
            </span>
            <div class="flex items-center">
                <button
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="
                        ($.uploadState.isDialogCompact
                            ? 'UPLOAD.EXPAND'
                            : 'UPLOAD.COLLAPSE'
                        ) | translate
                    "
                    [icon]="
                        $.uploadState.isDialogCompact
                            ? 'chevron-big-up'
                            : 'chevron-big-down'
                    "
                    [matTooltip]="
                        ($.uploadState.isDialogCompact
                            ? 'UPLOAD.EXPAND'
                            : 'UPLOAD.COLLAPSE'
                        ) | translate
                    "
                    [size]="'extra-small'"
                    (click)="
                        $.uploadState.isDialogCompact ? expand() : collapse()
                    "
                ></button>
                <button
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="'UPLOAD.CLOSE' | translate"
                    [disabled]="!!$.onLoading"
                    [icon]="'close'"
                    [matTooltip]="'UPLOAD.CLOSE' | translate"
                    [size]="'extra-small'"
                    (click)="close()"
                ></button>
            </div>
        </div>
        <ng-container *ngIf="!$.uploadState.isDialogCompact">
            <!-- banner -->
            <div
                *ngIf="$.onLoading"
                class="bg-surface-100 typo-xs-bold text-text-mid-contrast rounded-4 flex w-full cursor-pointer justify-end px-8 py-4"
                (click)="cancelNotYetLoadedUploads()"
            >
                {{ 'UPLOAD.CANCEL_LOADING' | translate }}
            </div>
            <!-- uploads -->
            <div
                class="max-h-264 flex flex-col"
                [ngClass]="{
                    'smooth-scroll overflow-y-auto':
                        !$.uploadState.isDialogCompact
                }"
            >
                <ng-container
                    *ngFor="
                        let item of $.attachments;
                        let index = index;
                        let last = last;
                        trackBy: trackAttachment
                    "
                >
                    <interacta-upload-attachment-item
                        [attachment]="item"
                        (cancelUpload)="cancelUpload(item)"
                        (retryUpload)="retryUpload(index, item)"
                    >
                    </interacta-upload-attachment-item>
                    <interacta-separator
                        *ngIf="!last"
                        thickness="soft"
                    ></interacta-separator>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-container>
