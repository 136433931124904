import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getValidationErrorPayload } from '@interacta-shared/data-access-error';
import { Index, filterMap, isDefined, uuid } from '@interacta-shared/util';
import { AIService } from '@modules/ai/services/ai.service';
import { AppSelectors, AppState } from '@modules/core/store';
import { AttachmentStorageType } from '@modules/post/models/attachment/attachment.model';
import { fromBasePost } from '@modules/post/models/generic-post.model';
import { AttachmentService } from '@modules/post/services/attachment.service';
import { PostService } from '@modules/post/services/post.service';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
    catchError,
    concatMap,
    filter,
    first,
    map,
    mergeMap,
    of,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs';
import { AIContextType, AIMessage, AIMessageType } from '../models/ai.model';
import { AIOverlayService } from '../services/ai-overlay.service';
import {
    AIActionsAPI,
    AICommunityActionsAPI,
    AIContentGenerationActionsAPI,
    AIGuideActionsAPI,
    AIPostActionsAPI,
} from './ai-api.actions';
import { AIActions } from './ai.actions';
import { selectActiveContext, selectCachedAttachment } from './ai.selectors';

@Injectable()
export class AIEffects {
    sendFeedbackToAI$ = createEffect(() =>
        this.actions.pipe(
            ofType(AIActions.sendFeedback),
            concatMap(({ key, messageId, like, contextType, threadId }) =>
                this.AIService.sendFeedbackToAI(
                    messageId,
                    like,
                    undefined,
                    undefined,
                ).pipe(
                    map(() =>
                        AIActionsAPI.sendFeedbackSuccess({
                            key,
                            messageId,
                            like,
                            contextType,
                            threadId,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            AIActionsAPI.sendFeedbackError({
                                key,
                                error,
                                messageId,
                                contextType,
                                threadId,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );
    sendFeedbackDetailToAI$ = createEffect(() =>
        this.actions.pipe(
            ofType(AIActions.sendFeedbackDetail),
            concatMap(
                ({
                    key,
                    messageId,
                    like,
                    feedbacks,
                    text,
                    contextType,
                    threadId,
                }) =>
                    this.AIService.sendFeedbackToAI(
                        messageId,
                        like,
                        feedbacks,
                        text,
                    ).pipe(
                        map(() =>
                            AIActionsAPI.sendFeedbackDetailSuccess({
                                key,
                                messageId,
                                like,
                                feedbacks,
                                text,
                                contextType,
                                threadId,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                AIActionsAPI.sendFeedbackDetailError({
                                    key,
                                    error,
                                    messageId,
                                    contextType,
                                    threadId,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );
    getResponseFromAI$ = createEffect(() =>
        this.actions.pipe(
            ofType(AIActions.getResponseFromAI),
            switchMap(
                ({
                    contextId,
                    contextType,
                    sessionId,
                    question,
                    clientId,
                    threadId,
                    attachmentId,
                }) =>
                    this.AIService.askQuestionToAI(
                        contextId,
                        contextType,
                        question,
                        sessionId,
                        clientId,
                        attachmentId,
                    ).pipe(
                        map(({ sessionId, answer, questionId, clientId }) =>
                            this.getSuccessActionResponse(
                                clientId,
                                contextId,
                                contextType,
                                sessionId,
                                questionId,
                                answer,
                                threadId,
                            ),
                        ),
                        catchError((error) => {
                            const { messageType, message } =
                                this.getErrorMessage(error);
                            return of(
                                this.getErrorActionResponse(
                                    contextId,
                                    contextType,
                                    sessionId,
                                    messageType,
                                    this.translateService.instant(message),
                                    threadId,
                                ),
                            );
                        }),
                    ),
            ),
        ),
    );
    changePostContextOnNavigation$ = createEffect(() =>
        this.actions.pipe(
            ofType(routerNavigatedAction),
            switchMap(() =>
                this.store.select(AppSelectors.selectRouteState).pipe(first()),
            ),
            filter((route) => route?.appBaseRoute === 'post-detail'),
            switchMap((route) =>
                this.postService
                    .getPost(route?.params['id'], false, true)
                    .pipe(
                        mergeMap((post) =>
                            this.postService.toDashboardPost(
                                fromBasePost(post),
                            ),
                        ),
                    ),
            ),
            filter((post) => isDefined(post)),
            map((post) =>
                AIActions.setActiveContext({
                    contextId: post.id,
                    contextType: AIContextType.POST,
                    workflowEnabled:
                        (post.metadata?.workflowDefinition &&
                            post.community.capabilities?.viewWorkflowHistory) ??
                        false,
                }),
            ),
        ),
    );
    changeCommunityContextOnNavigation$ = createEffect(() =>
        this.actions.pipe(
            ofType(routerNavigatedAction),
            switchMap(() =>
                this.store.select(AppSelectors.selectRouteState).pipe(first()),
            ),
            filter((route) => route?.appRoute === 'community'),
            switchMap((route) =>
                this.communitiesStateService.getCommunity(
                    route?.params['communityId'],
                ),
            ),
            filter((community) => isDefined(community)),
            map((community) =>
                AIActions.setActiveContext({
                    contextId: community.id,
                    contextType: AIContextType.COMMUNITY,
                    workflowEnabled:
                        (community.capabilities?.viewWorkflowHistory &&
                            !!community.metadata?.workflowDefinition) ??
                        false,
                }),
            ),
        ),
    );
    getAttachmentDetail$ = createEffect(() =>
        this.actions.pipe(
            ofType(AIActions.getAttachmentDetail),
            concatMap(({ id }) =>
                of(id).pipe(
                    withLatestFrom(
                        this.store.select(selectCachedAttachment(id)),
                    ),
                    switchMap(([id, attachment]) =>
                        attachment
                            ? of(
                                  AIActionsAPI.getAttachmentDetailSuccess({
                                      attachments: [attachment],
                                  }),
                              )
                            : this.attachmentService
                                  .getAttachmentDetail(id)
                                  .pipe(
                                      map((attachment) =>
                                          AIActionsAPI.getAttachmentDetailSuccess(
                                              { attachments: [attachment] },
                                          ),
                                      ),
                                      catchError((error) =>
                                          of(
                                              AIActionsAPI.getAttachmentDetailError(
                                                  { error, id },
                                              ),
                                          ),
                                      ),
                                  ),
                    ),
                ),
            ),
        ),
    );
    addThreadInActiveContext = createEffect(() =>
        this.actions.pipe(
            ofType(AIActions.addThreadInActiveContext),
            concatMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store
                            .select(selectActiveContext)
                            .pipe(filter(isDefined)),
                    ),
                    filterMap(([action, context]) =>
                        action && context.contextId && context.contextType
                            ? {
                                  action,
                                  contextId: context.contextId,
                                  contextType: context.contextType,
                              }
                            : undefined,
                    ),
                    switchMap(({ action, contextId, contextType }) =>
                        of(
                            AIActions.getResponseFromAI({
                                clientId: uuid(),
                                contextId,
                                contextType,
                                question: action.requestMessage,
                                sessionId: null,
                                timestamp: new Date(),
                                threadId: action.threadId,
                                attachmentId: action.attachment?.id,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );
    fetchAttachmentList$ = createEffect(() =>
        this.actions.pipe(
            ofType(AIActions.fetchAttachmentsList),
            concatMap(({ entityType, pageToken, postId, initial }) =>
                this.attachmentService
                    .loadPostAttachments(postId, {
                        nextPageToken: pageToken,
                        entityTypes: [entityType],
                        types: [AttachmentStorageType.STORAGE],
                        calculateTotalItemsCount: true,
                        pageSize: 5,
                        aiSupportedFilter: true,
                    })
                    .pipe(
                        map((result) =>
                            AIActionsAPI.fetchAttachmentListSuccess({
                                list: result,
                                entityType,
                                initial,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                AIActionsAPI.fetchAttachmentListError({
                                    error,
                                    entityType,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );
    fetchContentGeneration$ = createEffect(() =>
        this.actions.pipe(
            ofType(AIActions.fetchContentGeneration),
            concatMap(({ initialContent, contentGenerationType }) =>
                this.AIService.generateContent(
                    initialContent,
                    contentGenerationType,
                ).pipe(
                    map(({ contents }) =>
                        AIContentGenerationActionsAPI.fetchGeneratedContentSuccess(
                            {
                                contents,
                                generatedContentType: contentGenerationType,
                            },
                        ),
                    ),
                    catchError((error) =>
                        of(
                            AIContentGenerationActionsAPI.fetchGeneratedContentError(
                                {
                                    error,
                                    contentGenerationType,
                                },
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );
    toggleOpen$ = createEffect(
        () =>
            this.actions.pipe(
                ofType(AIActions.toggleAIPanel),
                tap(({ isOpen }) => {
                    isOpen
                        ? this.AIOverlayService.open()
                        : this.AIOverlayService.close();
                }),
            ),
        { dispatch: false },
    );

    sendGeneratedContentFeedbackToAI$ = createEffect(() =>
        this.actions.pipe(
            ofType(AIActions.sendGeneratedContentFeedback),
            concatMap(
                ({
                    sourceContent,
                    generatedContent,
                    feedbackId,
                    contentGeneratorType,
                    toneType,
                    like,
                    feedbacks,
                    text,
                    contextType,
                    confirmed,
                }) =>
                    this.AIService.sendContentGeneratorFeedback(
                        sourceContent,
                        generatedContent,
                        feedbackId,
                        contentGeneratorType,
                        toneType,
                        like,
                        feedbacks,
                        text,
                        confirmed,
                    ).pipe(
                        map((feedbackId) =>
                            AIContentGenerationActionsAPI.sendGeneratedContentFeedbackSuccess(
                                {
                                    feedbackId,
                                    feedbacks,
                                    note: text,
                                    like,
                                    contextType,
                                    contentGenerationType: contentGeneratorType,
                                    toneType,
                                    confirmed,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                AIContentGenerationActionsAPI.sendGeneratedContentFeedbackError(
                                    {
                                        error,
                                        contextType,
                                        contentGenerationType:
                                            contentGeneratorType,
                                        toneType,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    constructor(
        private actions: Actions,
        private AIService: AIService,
        private translateService: TranslateService,
        private store: Store<AppState>,
        private communitiesStateService: CommunitiesStateService,
        private postService: PostService,
        private attachmentService: AttachmentService,
        private AIOverlayService: AIOverlayService,
    ) {}

    private getSuccessActionResponse(
        clientId: string,
        contextId: number,
        contextType: AIContextType,
        sessionId: number | null,
        questionId: number,
        answer: AIMessage,
        threadId?: Index,
    ) {
        switch (contextType) {
            case AIContextType.POST:
                return AIPostActionsAPI.getPostResponseFromAISuccess({
                    clientId,
                    postId: contextId,
                    sessionId,
                    answer,
                    questionId,
                    threadId,
                });
            case AIContextType.COMMUNITY:
                return AICommunityActionsAPI.getCommunityResponseFromAISuccess({
                    clientId,
                    communityId: contextId,
                    sessionId,
                    answer,
                    questionId,
                    threadId,
                });
            default:
                return AIGuideActionsAPI.getGuideResponseFromAISuccess({
                    clientId,
                    sessionId,
                    answer,
                    questionId,
                    threadId,
                });
        }
    }

    private getErrorActionResponse(
        contextId: number,
        contextType: AIContextType,
        sessionId: number | null,
        messageType: AIMessageType,
        message: string,
        threadId?: Index,
    ) {
        switch (contextType) {
            case AIContextType.POST:
                return AIPostActionsAPI.getPostResponseFromAIError({
                    postId: contextId,
                    sessionId,
                    messageType,
                    message,
                    threadId,
                });
            case AIContextType.COMMUNITY:
                return AICommunityActionsAPI.getCommunityResponseFromAIError({
                    communityId: contextId,
                    sessionId,
                    messageType,
                    message,
                    threadId,
                });
            default:
                return AIGuideActionsAPI.getGuideResponseFromAIError({
                    sessionId,
                    messageType,
                    message,
                    threadId,
                });
        }
    }

    private getErrorMessage(error: unknown): {
        messageType: AIMessageType;
        message: string;
    } {
        if (error instanceof HttpErrorResponse) {
            if (error.status === 429) {
                return {
                    messageType: AIMessageType.TOO_MANY_REQUESTS_ERROR,
                    message: 'AI.ERROR_429',
                };
            } else {
                const payload = getValidationErrorPayload(error);
                const code = payload?.validationErrors?.list?.[0]?.code;

                switch (code) {
                    case AIMessageType.AI_EMPTY_ANSWER:
                        return {
                            messageType: AIMessageType.AI_EMPTY_ANSWER,
                            message: 'AI.ERROR_EMPTY_MESSAGE',
                        };
                    case AIMessageType.SAFETY_REASON_DANGEROUS_CONTENT:
                    case AIMessageType.SAFETY_REASON_HARASSMENT:
                    case AIMessageType.SAFETY_REASON_HATE_SPEECH:
                    case AIMessageType.SAFETY_REASON_SEXUALLY_EXPLICIT:
                        return {
                            messageType: code,
                            message: 'AI.ERROR_SAFETY',
                        };
                    case AIMessageType.SAFETY_REASON_UNSPECIFIED:
                    case AIMessageType.SAFETY_REASON_UNRECOGNIZED:
                        return {
                            messageType: code,
                            message: 'AI.ERROR_EMPTY_MESSAGE',
                        };

                    case AIMessageType.FETCH_FILE_ERROR:
                        return {
                            messageType: code,
                            message: 'AI.ERROR_FETCH_FILE_ERROR',
                        };
                    case AIMessageType.UNSUPPORTED_TYPE:
                        return {
                            messageType: code,
                            message: 'AI.ERROR_UNSUPPORTED_TYPE',
                        };
                }
            }
        }
        return {
            messageType: AIMessageType.ERROR,
            message: 'AI.ERROR',
        };
    }
}
