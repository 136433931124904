import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { TipService } from '@interacta-shared/feature-tip';
import { ThemeMode } from '@interacta-shared/util-common';
import {
    getContentGenerationTypeLabel,
    getToneOfVoiceTypeLabel,
} from '@modules/ai/helpers/ai.utils';
import {
    AIContentGenerationType,
    AIContextType,
    FeedbackChip,
} from '@modules/ai/models/ai.model';
import { AIActions } from '@modules/ai/store/ai.actions';
import {
    AIGeneratedContent,
    AIGeneratedContentDetail,
} from '@modules/ai/store/ai.reducer';
import { selectActiveGeneratedContent } from '@modules/ai/store/ai.selectors';
import { Store } from '@ngrx/store';
import { Delta } from 'quill/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'interacta-ai-panel-content-generation-detail',
    templateUrl: './ai-panel-content-generation-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AIPanelContentGenerationDetailComponent
    implements OnInit, OnChanges
{
    @Input({ required: true }) section!: AIContentGenerationType;
    @Input({ required: true }) theme!: ThemeMode;
    @Input({ required: true }) initialContent!: Delta | null;
    @Input() activeSection = false;

    @Output() backClicked = new EventEmitter<void>();

    private initialized = false;
    private readonly store = inject(Store);
    private readonly tipService = inject(TipService);

    generatedContent$!: Observable<AIGeneratedContent | null>;

    getContentGenerationTypeLabel = getContentGenerationTypeLabel;
    getToneOfVoiceTypeLabel = getToneOfVoiceTypeLabel;

    AIContentGenerationType = AIContentGenerationType;

    ngOnInit(): void {
        this.generatedContent$ = this.store.select(
            selectActiveGeneratedContent(this.section),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.initialContent && this.initialContent) {
            this.initialized = false;
        }

        if (changes.activeSection && this.activeSection) {
            this.initSection();
        }
    }

    initSection(): void {
        if (this.initialized) return;

        this.store.dispatch(
            AIActions.fetchContentGeneration({
                initialContent: this.initialContent,
                contentGenerationType: this.section,
            }),
        );

        this.initialized = true;
    }

    confirm($event: AIGeneratedContentDetail): void {
        this.store.dispatch(
            AIActions.setGeneratedContentInActiveContext({
                generatedContent: $event.delta,
            }),
        );

        this.store.dispatch(AIActions.toggleAIPanel({ isOpen: false }));

        this.tipService.success('AI.CONTENT_GENERATION.CONFIRM_SUCCESS');

        this.store.dispatch(
            AIActions.sendGeneratedContentFeedback({
                feedbackId: $event.feedbackId ?? null,
                like: $event.like,
                sourceContent: this.initialContent,
                generatedContent: $event.delta,
                toneType: $event.tone,
                contentGeneratorType: this.section,
                contextType: AIContextType.CONTENT_GEN,
                feedbacks: $event.feedbacks,
                text: $event.feedbackText,
                confirmed: true,
            }),
        );
    }

    retry(): void {
        this.store.dispatch(
            AIActions.fetchContentGeneration({
                initialContent: this.initialContent,
                contentGenerationType: this.section,
            }),
        );
    }

    setLike(content: AIGeneratedContentDetail, like: boolean): void {
        const likeToSet = content.like === like ? undefined : like;

        this.store.dispatch(
            AIActions.sendGeneratedContentFeedback({
                feedbackId: content.feedbackId ?? null,
                sourceContent: this.initialContent,
                generatedContent: content.delta,
                like: likeToSet,
                toneType: content.tone,
                contentGeneratorType: this.section,
                contextType: AIContextType.CONTENT_GEN,
                feedbacks: undefined,
                text: undefined,
                confirmed: undefined,
            }),
        );
    }

    sendFeedbackDetail(
        content: AIGeneratedContentDetail,
        $event: {
            chips: FeedbackChip[];
            moreDetails: string | null;
        },
    ): void {
        if (content.like === undefined) return;
        this.store.dispatch(
            AIActions.sendGeneratedContentFeedback({
                feedbackId: content.feedbackId ?? null,
                sourceContent: this.initialContent,
                generatedContent: content.delta,
                like: content.like,
                toneType: content.tone,
                contentGeneratorType: this.section,
                contextType: AIContextType.CONTENT_GEN,
                feedbacks: $event.chips?.map((c) => c.id),
                text: $event.moreDetails ?? undefined,
                confirmed: undefined,
            }),
        );
    }
}
